import React from "react";

export default function MenuItemCard({ item, colour }) {
  return (
    <div className={`${colour} m-6 border-2 px-3 flex flex-col`}>
      <div className="text-xl mb-4 mt-4 font-bold">{item.name}</div>
      <div className="mb-2">{item.description}</div>
      <div className="mr-6 mt-4 mb-3 font-extrabold flex self-end">
        {item.price} lv
      </div>
    </div>
  );
}
