import React from "react";
import logo from "../static/logo.png";
import { Input } from "antd";
import { Button } from "antd";
import { useState } from "react";
import AuthenticationService from "../services/authenticationService";

export default function LoginPage() {
  const [user, setUser] = useState({ email: "", password: "" });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const submit = async () => {
    try {
      await AuthenticationService.login(user.email, user.password);
      window.location.href = "/";
    } catch (error) {
      alert("There was an error logging in. Please try again.");
    }
  };

  return (
    <div className="grid grid-cols-3 h-screen ">
      {/*Logo part */}
      <div className="col-span-2 content-center">
        <div className="flex justify-center ">
          <img src={logo} alt="Strandja logo" />
        </div>
      </div>
      {/*Input fields part*/}
      <div className="bg-strandja-yellow content-center">
        <div className="flex flex-wrap justify-center gap-12 w-5/6 m-14">
          <Input
            placeholder="Email"
            size="large"
            onInput={handleChange}
            name="email"
          />
          <Input.Password
            placeholder="Password"
            size="large"
            onInput={handleChange}
            name="password"
          />
          <div className="w-2/6">
            <Button
              type="primary"
              block
              size="large"
              className="bg-black"
              onClick={submit}
            >
              LogIn
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
