import React, { useState } from "react";
import Modal from "../components/Modal/Modal";
import { Input, Typography, InputNumber, Select } from "antd";
import MenuService from "../services/menuService";

const { TextArea } = Input;

const AddNewMenuItemModal = ({ open, onCancel, onOk, footer }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [newMenuItem, setNewMenuItem] = useState({
    name: "",
    description: "",
    price: 0,
    item_type: "",
    status: "active",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMenuItem((prev) => ({ ...prev, [name]: value }));
  };

  const handlePriceChange = (value) => {
    setNewMenuItem((prev) => ({ ...prev, price: value }));
  };

  const handleTypeChange = (value) => {
    setNewMenuItem((prev) => ({ ...prev, item_type: value }));
  };

  const isFormValid = () => {
    return (
      newMenuItem.name.trim() !== "" &&
      newMenuItem.description.trim() !== "" &&
      newMenuItem.price > 0 &&
      newMenuItem.item_type.trim() !== ""
    );
  };

  const addNewMenuItem = async () => {
    setLoading(true);
    try {
      await MenuService.createMenuItem(newMenuItem);
      setLoading(false);
      setNewMenuItem({
        name: "",
        description: "",
        price: 0,
        item_type: "",
        status: "active",
      });
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const handleOk = async () => {
    await addNewMenuItem();
    if (!error) {
      onOk();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={handleOk}
      okButtonProps={{
        size: "large",
        className: "bg-strandja-dark-cyan",
        loading: loading,
        disabled: !isFormValid() || loading,
      }}
      cancelButtonProps={{ size: "large" }}
      title="Add New Menu Item"
      okText="Add New Item"
      footer={footer}
    >
      <div className="grid grid-rows-3 gap-3 h-full">
        <div>
          <Typography.Title level={5}>Name</Typography.Title>
          <Input
            name="name"
            placeholder="Name"
            value={newMenuItem.name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <Typography.Title level={5}>Description</Typography.Title>
          <TextArea
            name="description"
            rows={4}
            placeholder="Description"
            value={newMenuItem.description}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex justify-between">
          <div className="w-64">
            <Typography.Title level={5}>Price</Typography.Title>
            <InputNumber
              name="price"
              placeholder="Price"
              min={1}
              max={9999}
              value={newMenuItem.price}
              onChange={handlePriceChange}
              style={{ width: "100%" }}
            />
          </div>
          <div className="w-64">
            <Typography.Title level={5}>Type</Typography.Title>
            <Select
              name="type"
              style={{ width: "100%" }}
              value={newMenuItem.item_type}
              onChange={handleTypeChange}
              options={[
                { value: "food", label: <span>Food</span> },
                { value: "drink", label: <span>Drink</span> },
              ]}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewMenuItemModal;
