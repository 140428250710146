// AuthenticationService.js
import axiosInstance  from '../helpers/axiosInstance';

const AuthenticationService = {
  login: async (email, password) => {
    try {
      const response = await axiosInstance.post('/login', {
        user: { email, password },
      });
      const token = response.headers['authorization'];
      localStorage.setItem('token', token);
      return response.data;
    } catch (error) {
      console.error('There was an error logging in:', error);
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    window.location.href = '/admin';
  },
};

export default AuthenticationService;
