import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableService from "../services/tableService";
import MenuService from "../services/menuService";
import FooterNavBar from "../components/FooterNavBar/FooterNavBar";
import { Col, Row } from "antd";
import { Button, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import OrderService from "../services/orderService";

export default function OrderDetailsPage() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const [table, setTable] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("food");
  const [orderItems, setOrderItems] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [order, setOrder] = useState();
  const navigate = useNavigate();

  const fetchTable = async () => {
    const fetchedTable = await TableService.getTable(
      searchParams.get("tableId"),
    );
    setTable(fetchedTable);
  };

  const fetchMenuItems = async () => {
    const fetchedMenuItems = await MenuService.getMenuItems();
    setMenuItems(fetchedMenuItems);

    if (searchParams.get("orderId")) {
      await fetchOrder();
    }
  };

  const fetchOrder = async () => {
    const fetchedOrder = await OrderService.getOrderDetails(
      searchParams.get("orderId"),
    );
    setOrder(fetchedOrder);
    setCustomerName(fetchedOrder.data.name);
    setOrderItems(
      fetchedOrder.data.order_items.data.map((orderItem) => ({
        id: orderItem.id,
        menu_item_id: orderItem.menu_item.data.id,
        quantity: orderItem.quantity,
      })),
    );
  };

  useEffect(() => {
    if (!searchParams.get("tableId") && !searchParams.get("orderId")) {
      return navigate("/tables");
    }

    fetchTable();
    fetchMenuItems();
  }, []);

  const handleAddToOrder = (menuItem) => {
    if (
      orderItems.some(
        (orderItem) => orderItem.menu_item_id === menuItem.id && !orderItem.id,
      )
    ) {
      setOrderItems(
        orderItems.map((orderItem) => {
          if (orderItem.menu_item_id === menuItem.id && !orderItem.id) {
            return { ...orderItem, quantity: orderItem.quantity + 1 };
          }
          return orderItem;
        }),
      );
      return;
    }

    setOrderItems([...orderItems, { menu_item_id: menuItem.id, quantity: 1 }]);
  };

  const handleIncrementQuantity = (menuItemId) => {
    setOrderItems(
      orderItems.map((orderItem) => {
        if (orderItem.menu_item_id === menuItemId && !orderItem.id) {
          return { ...orderItem, quantity: orderItem.quantity + 1 };
        }
        return orderItem;
      }),
    );
  };

  const handleDecrementQuantity = (menuItemId) => {
    setOrderItems(
      orderItems
        .filter((orderItem) => {
          if (orderItem.menu_item_id === menuItemId && !orderItem.id) {
            if (orderItem.quantity === 1) {
              return false;
            }
          }
          return orderItem;
        })
        .map((orderItem) => {
          if (orderItem.menu_item_id === menuItemId && !orderItem.id) {
            return { ...orderItem, quantity: orderItem.quantity - 1 };
          }
          return orderItem;
        }),
    );
  };

  const submitOrder = async () => {
    const total = calculateTotal();
    await OrderService.upsertOrder({
      id: order?.data.id,
      table_id: table.id,
      name: customerName,
      order_items_attributes: orderItems.map((orderItem) => ({
        ...orderItem,
        menu_item_id: orderItem.menu_item_id,
      })),
      total: total,
      status: "open",
    });

    navigate("/tables");
  };

  const calculateTotal = () => {
    return orderItems
      .map((orderItem) => {
        return (
          orderItem.quantity *
          menuItems.find((mi) => mi.id === orderItem.menu_item_id).price
        );
      })
      .reduce((acc, curr) => acc + curr, 0)
      .toFixed(2);
  };

  return (
    <div>
      <Row className="h-screen">
        <Col span={12}>
          <Row className="h-screen overflow-auto content-start pb-52">
            {menuItems
              .filter((menuItem) => menuItem.item_type === selectedCategory)
              .map((menuItem) => (
                <Col span={12} key={menuItem.id}>
                  <div
                    className={`h-36 m-6 border-2 p-2`}
                    onClick={() => handleAddToOrder(menuItem)}
                  >
                    <div className="text-xl mb-2 font-bold">
                      {menuItem.name}
                    </div>
                    <div className="mb-2 h-14 overflow-scroll">
                      {menuItem.description}
                    </div>
                    <div className="float-right mr-6 font-extrabold absolute bottom-10 right-3">
                      {menuItem.price} lv
                    </div>
                  </div>
                </Col>
              ))}
            <Col span={12}>
              <nav className="fixed w-1/2 bottom-20 left-0 flex justify-between bg-strandja-soft-red p-4 px-20">
                <div>
                  <Button
                    type="text"
                    size="large"
                    className="text-xl pb-0 pt-0"
                    onClick={() => setSelectedCategory("food")}
                    style={{
                      backgroundColor:
                        selectedCategory === "food" ? "#EBD188" : "transparent",
                    }}
                  >
                    Food
                  </Button>
                </div>
                <div>
                  <Button
                    type="text"
                    size="large"
                    className="text-xl pb-0 pt-0"
                    onClick={() => setSelectedCategory("drink")}
                    style={{
                      backgroundColor:
                        selectedCategory === "drink"
                          ? "#EBD188"
                          : "transparent",
                    }}
                  >
                    Drinks
                  </Button>
                </div>
              </nav>
            </Col>
          </Row>
        </Col>
        <Col span={12} className="bg-strandja-soft-grey h-screen pb-10">
          <h2 className={`text-xl text-center mt-5 font-bold`}>
            Table {table?.id}
          </h2>
          <div className="flex flex-col items-center">
            <div className="w-3/4 overflow-auto">
              <Input
                className="mt-3 bg-white"
                size="large"
                placeholder="Name"
                prefix={<UserOutlined />}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
              <div className="border-b-1 max-h-[440px] overflow-scroll mt-5">
                {orderItems.map((orderItem) => (
                  <div
                    className="flex justify-between border-b-2 p-2"
                    key={orderItem?.id || orderItem.menu_item_id}
                  >
                    <div>
                      <Button
                        type="text"
                        className="text-xl"
                        onClick={() =>
                          handleDecrementQuantity(orderItem.menu_item_id)
                        }
                        disabled={!!orderItem?.id}
                      >
                        -
                      </Button>
                      <span className="text-lg">{orderItem.quantity}</span>
                      <Button
                        type="text"
                        className="text-xl"
                        onClick={() =>
                          handleIncrementQuantity(orderItem.menu_item_id)
                        }
                        disabled={!!orderItem?.id}
                      >
                        +
                      </Button>
                    </div>
                    <div className="text-center text-lg">
                      {
                        menuItems.find((mi) => mi.id === orderItem.menu_item_id)
                          .name
                      }
                    </div>
                    <div className="text-lg">
                      {(
                        orderItem.quantity *
                        menuItems.find((mi) => mi.id === orderItem.menu_item_id)
                          .price
                      ).toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="flex justify-between p-2"
                style={{ display: orderItems.length ? "flex" : "none" }}
              >
                <div></div>
                <div className="text text-lg"></div>
                <div className="text-lg font-bold">
                  Total:{" "}
                  {orderItems
                    .map((orderItem) => {
                      return (
                        orderItem.quantity *
                        menuItems.find((mi) => mi.id === orderItem.menu_item_id)
                          .price
                      );
                    })
                    .reduce((acc, curr) => acc + curr, 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>
            <div
              className="w-3/4"
              style={{ display: orderItems.length ? "flex" : "none" }}
            >
              <Button
                type="default"
                size="large"
                className="w-3/4 bottom-25 mb-3 absolute bg-strandja-orange"
                onClick={() => submitOrder()}
                disabled={!customerName || !orderItems.length}
              >
                Submit Order
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <FooterNavBar />
    </div>
  );
}
