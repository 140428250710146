import { deserialise } from "kitsu-core";
import axiosInstance from "../helpers/axiosInstance";

const OrderService = {
  getOrders: async (filters = {}) => {
    try {
      const queryParameters = new URLSearchParams();

      // Add filters to the query parameters if they are provided
      if (filters.table_id) {
        queryParameters.append("filter[table_id]", filters.table_id);
      }
      if (filters.status) {
        queryParameters.append("filter[status]", filters.status);
      }
      if (filters.name) {
        queryParameters.append("filter[name]", filters.name);
      }

      const response = await axiosInstance.get("/orders", {
        params: queryParameters,
      });

      const result = response.data;
      return deserialise(result);
    } catch (error) {
      console.log(error.message);
    }
  },

  getOrderDetails: async (orderId) => {
    try {
      const response = await axiosInstance.get(`/orders/${orderId}`);

      const result = response.data;
      return deserialise(result);
    } catch (error) {
      console.error("Error fetching order details:", error);
      throw error;
    }
  },

  completeOrder: async (orderId) => {
    try {
      const response = await axiosInstance.patch(`/orders/${orderId}`, {
        order: {
          status: "closed",
        },
      });

      const result = response.data;
      return deserialise(result);
    } catch (error) {
      console.error("Error completing the order:", error);
      throw error;
    }
  },

  deleteOrder: async (orderId) => {
    try {
      await axiosInstance.delete(`/orders/${orderId}`);
    } catch (e) {
      console.log(e);
    }
  },

  upsertOrder: async (order) => {
    try {
      let response;

      if (order.id) {
        const id = order.id;
        delete order.id;
        response = await axiosInstance.put(`/orders/${id}`, {
          order,
        });
      } else {
        response = await axiosInstance.post("/orders", {
          order,
        });
      }

      const result = response.data;
      return deserialise(result);
    } catch (error) {
      console.error("Error creating the order:", error);
      throw error;
    }
  },
};

export default OrderService;
