import React, { useEffect, useState } from "react";
import CustomTable from "../components/CustomTable/CustomTable";
import FooterNavBar from "../components/FooterNavBar/FooterNavBar";
import { Spin, Tooltip, Button, Popconfirm } from "antd";
import OrderService from "../services/orderService";
import OrderItemService from "../services/orderItemService";
import OrderDetailsModal from "../dialogs/orderDetailsModal";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

export default function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    table_id: searchParams.get("tableId"),
    status: searchParams.get("status"),
    name: searchParams.get("name"),
  });

  useEffect(() => {
    fetchOrders();
  }, [filters]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const result = await OrderService.getOrders(filters);
      setOrders(result.data);
    } catch (error) {
      alert("There was an error fetching orders. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      await OrderService.deleteOrder(orderId);
      await fetchOrders();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleRowClick = async (record) => {
    try {
      const orderDetails = await OrderService.getOrderDetails(record.key);
      setSelectedOrder(orderDetails.data);
      setIsModalVisible(true);
    } catch (error) {
      alert("There was an error fetching order details. Please try again.");
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
  };

  const handleOrderComplete = async (orderId) => {
    try {
      await OrderService.completeOrder(orderId);
      await fetchOrders();
      setIsModalVisible(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  const dataSource = orders.map((order) => ({
    key: order.id,
    created_at: moment(order.created_at).format("DD-MM-YYYY HH:mm"),
    table_id: order.table.data.id,
    name: order.name,
    total: order.total,
    status: order.status,
  }));

  const columns = [
    {
      title: "",
      dataIndex: "id",
      key: "x",
      render: (text, record) => (
        <Tooltip title="Delete Order">
          <Popconfirm
            title="Are you sure you want to delete this order?"
            onConfirm={(e) => {
              e.stopPropagation();
              deleteOrder(record.key);
            }}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: "pointer", fontSize: "18px" }}
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) =>
        moment(a.created_at, "DD-MM-YYYY HH:mm").unix() -
        moment(b.created_at, "DD-MM-YYYY HH:mm").unix(),
    },
    {
      title: "Table",
      dataIndex: "table_id",
      key: "table",
      sorter: (a, b) => a.table_id - b.table_id,
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => (
        <span style={{ color: text === "open" ? "green" : "red" }}>{text}</span>
      ),
    },
  ];

  return (
    <div className="h-screen">
      <div className="row-span-2">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Spin size="large" />
          </div>
        ) : (
          <CustomTable
            dataSource={dataSource}
            columns={columns}
            className="m-5"
            pagination={{ defaultPageSize: 9, position: ["bottomCenter"] }}
            onRow={(record) => ({
              onClick: () => {
                handleRowClick(record);
              },
            })}
          />
        )}
      </div>
      <FooterNavBar />
      <OrderDetailsModal
        open={isModalVisible}
        onCancel={handleModalClose}
        onOk={() => handleOrderComplete(selectedOrder.id)}
        chosenOrder={selectedOrder}
      />
    </div>
  );
}
