import { useEffect, useState } from "react";
import { Stage, Layer } from "react-konva";
import FooterNavBar from "../components/FooterNavBar/FooterNavBar";
import HorizontalTable from "../components/Tables/HorizontalTable";
import VerticalTable from "../components/Tables/VerticalTable";
import CircleTable from "../components/Tables/CircleTable";
import SquareTable from "../components/Tables/SquareTable";
import { WIDTH, HEIGHT } from "../helpers/table-helper";
import { Button, Dropdown, Space, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";
import TableService from "../services/tableService";
import { useNavigate } from "react-router-dom";

export default function TablesPage() {
  const [tables, setTables] = useState([]);
  const [editing, setEditing] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const tableTypes = [
    { key: "horizontal", label: "Horizontal Table" },
    { key: "vertical", label: "Vertical Table" },
    { key: "square", label: "Square Table" },
    { key: "circle", label: "Circle Table" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    fetchTables();
  }, []);

  const fetchTables = async () => {
    const fetchedTables = await TableService.getTables();
    setTables(fetchedTables);
  };

  const handleDragStart = (e) => {
    const id = e.target.id();

    setTables(
      tables.map((table) => {
        return {
          ...table,
          isDragging: table.id === id,
        };
      }),
    );
  };
  const handleDragEnd = (e) => {
    setTables(
      tables.map((table) => {
        return {
          ...table,
          isDragging: false,
          x: table.isDragging ? e.target.x() : table.x,
          y: table.isDragging ? e.target.y() : table.y,
        };
      }),
    );
  };
  const addTable = (key) => {
    const id = (Math.max(...tables.map((table) => table.id), 0) + 1).toString();
    setTables([
      ...tables,
      {
        id: id,
        x: key === "circle" ? 50 : 0,
        y: key === "circle" ? 50 : 0,
        isDragging: false,
        shape: key,
        name: `T${id}`,
        status: "available",
        pending: true,
      },
    ]);
  };
  const deleteSelectedTable = async () => {
    try {
      if (!selectedTable.pending) {
        await TableService.deleteTable(selectedTable.id);
      }

      setTables(tables.filter((table) => table.id !== selectedTable.id));
      setSelectedTable(null);
    } catch (error) {
      alert("Failed to delete table. Please try again later.");
    }
  };
  const toggleEditingAndSave = async () => {
    if (editing) {
      try {
        const updatedTables = await TableService.storeTables(tables);
        setTables(updatedTables);
      } catch (error) {
        alert("Failed to save tables. Please try again later.");
        return;
      }
    }

    setEditing(!editing);
  };

  const handleOnTableClick = (table) => {
    if (editing) {
      return setSelectedTable(table);
    }

    if (table.status === "occupied") {
      return navigate(`/orders?tableId=${table.id}&status=open`);
    }

    navigate(`/orderDetails?tableId=${table.id}`);
  };

  return (
    <div style={{ margin: "20px" }} className="h-screen fixed">
      <Button
        onClick={() => toggleEditingAndSave(!editing)}
        className="mb-5 mr-5"
        type="primary"
        danger={editing}
      >
        {editing ? "Stop Editing and Save" : "Enable Editing"}
      </Button>

      <Dropdown
        menu={{ items: tableTypes, onClick: ({ key }) => addTable(key) }}
        trigger={["click"]}
        className={{ hidden: !editing, "mb-5": true, "mr-5": true }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space style={{ cursor: "pointer" }}>
            Add Table
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>

      <Button
        onClick={() => deleteSelectedTable()}
        type="primary"
        danger={editing}
        className={{ hidden: !editing, "mb-5": true, "mr-5": true }}
        disabled={!selectedTable}
      >
        Delete Table {selectedTable?.id}
      </Button>

      <Stage
        width={WIDTH}
        height={HEIGHT}
        style={{ border: "1px solid black" }}
      >
        <Layer>
          {tables.map((table) => {
            if (table.shape === "horizontal") {
              return (
                <HorizontalTable
                  key={table.id}
                  id={table.id}
                  x={table.x}
                  y={table.y}
                  editable={editing}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  onClick={() => handleOnTableClick(table)}
                  status={table.status}
                />
              );
            } else if (table.shape === "vertical") {
              return (
                <VerticalTable
                  key={table.id}
                  id={table.id}
                  x={table.x}
                  y={table.y}
                  editable={editing}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  onClick={() => handleOnTableClick(table)}
                  status={table.status}
                />
              );
            } else if (table.shape === "square") {
              return (
                <SquareTable
                  key={table.id}
                  id={table.id}
                  x={table.x}
                  y={table.y}
                  editable={editing}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  onClick={() => handleOnTableClick(table)}
                  status={table.status}
                />
              );
            } else if (table.shape === "circle") {
              return (
                <CircleTable
                  key={table.id}
                  id={table.id}
                  x={table.x}
                  y={table.y}
                  editable={editing}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  onClick={() => handleOnTableClick(table)}
                  status={table.status}
                />
              );
            }
          })}
        </Layer>
      </Stage>

      <FooterNavBar />
    </div>
  );
}
