export const WIDTH = 1140;
export const HEIGHT = 580;

export const isInBounds = (x, y, shapeWidth, shapeHeight) => {
  return (
    x >= 0 && x <= WIDTH - shapeWidth && y >= 0 && y <= HEIGHT - shapeHeight
  );
};

export const handleDragBound = (pos, shapeWidth, shapeHeight) => {
  if (isInBounds(pos.x, pos.y, shapeWidth, shapeHeight)) {
    return { x: pos.x, y: pos.y };
  }

  return {
    x: Math.min(Math.max(pos.x, 0), WIDTH - shapeWidth),
    y: Math.min(Math.max(pos.y, 0), HEIGHT - shapeHeight),
  };
};
