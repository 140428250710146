import React from "react";
import NavBar from "../components/NavBar/NavBar";
import FooterNavBar from "../components/FooterNavBar/FooterNavBar";
import { Navigate } from "react-router-dom";

export default function HomePage() {
  if (localStorage.getItem("token")) {
    return <Navigate to={"/tables"} />;
  }

  return <Navigate to={"/menu"} />;
}
