import React, { useEffect, useState } from "react";
import { Spin } from "antd"; // Importing the Ant Design spinner
import "antd/dist/reset.css"; // Importing the Ant Design styles
import logo from "../static/logo.png";
import MenuItemCard from "../components/MenuItemCard/MenuItemCard";
import MenuService from "../services/menuService";

export default function MenuPage() {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("food"); // Default category is food

  useEffect(() => {
    const fetchMenuItems = async () => {
      setLoading(true);
      try {
        const items =
          await MenuService.getMenuItemsByCategory(selectedCategory);
        setMenuItems(items);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuItems();
  }, [selectedCategory]);

  const colours = [
    "bg-strandja-soft-orange",
    "bg-strandja-orange",
    "bg-strandja-green",
    "bg-strandja-soft-red",
  ];

  return (
    <div className="bg-strandja-dark-grey h-full scroll-auto border-2">
      <div>
        <div className="bg-strandja-yellow flex justify-center">
          <img
            src={logo}
            alt="Strandja Logo"
            className="my-5"
            style={{ maxWidth: "300px" }}
          />
        </div>
        <div className="flex flex-wrap">
          {/* Tabs for Drinks & Food */}
          <div
            className="w-1/2 bg-strandja-dark-red p-3 font-bold text-xl border-y-2 
                      border-r-2 cursor-pointer hover:bg-strandja-medium-red "
            onClick={() => setSelectedCategory("food")}
          >
            Food
          </div>
          <div
            className="w-1/2 bg-strandja-dark-red p-3 font-bold text-xl border-y-2 cursor-pointer
                          hover:bg-strandja-medium-red"
            onClick={() => setSelectedCategory("drink")}
          >
            Drinks
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center bg-white border-none h-screen bg-opacity-10">
            <Spin size="large" />
          </div>
        ) : error ? (
          <div className="bg-white border-none h-screen">Error: {error}</div>
        ) : (
          <div>
            {menuItems.map((item, index) => {
              return (
                <MenuItemCard
                  key={item.id}
                  item={item}
                  colour={colours[index % colours.length]}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
