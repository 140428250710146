import React, { useEffect, useState } from "react";
import MenuService from "../services/menuService";
import FooterNavBar from "../components/FooterNavBar/FooterNavBar";
import { Tooltip, Button, Popconfirm, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CustomTable from "../components/CustomTable/CustomTable";
import { EditableCell } from "../components/EditableCell/EditableCell";
import { Form, Typography } from "antd";
import AddNewMenuItemModal from "../dialogs/addNewMenuItemModal";

export default function MenuItemsPage() {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    setLoading(true);
    try {
      const items = await MenuService.getMenuItems();
      setMenuItems(items);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteMenuItem = async (menuItemId) => {
    try {
      await MenuService.deleteMenuItem(menuItemId);
      await fetchMenuItems();
    } catch (e) {
      console.log(e.message);
    }
  };

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      description: "",
      price: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();
      await MenuService.updateMenuItem(row, key);
      await fetchMenuItems();

      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleOk = async () => {
    await fetchMenuItems();
    setIsModalVisible(false);
  };

  const dataSource = menuItems.map((menuItem) => ({
    key: menuItem.id,
    id: menuItem.id,
    name: menuItem.name,
    description: menuItem.description,
    price: menuItem.price,
    type: menuItem.item_type,
  }));

  const columns = [
    {
      title: "",
      dataIndex: "id",
      key: "x",
      render: (text, record) => (
        <Tooltip title="Delete Menu Item">
          <Popconfirm
            title="Are you sure you want to delete this menu item?"
            onConfirm={(e) => {
              e.stopPropagation();
              deleteMenuItem(record.key);
            }}
            onCancel={(e) => e.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: "pointer", fontSize: "18px" }}
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      editable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleSave(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "price" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <div className="grid scroll-auto">
        <div className="mx-4 mt-5">
          <Button
            onClick={() => setIsModalVisible(true)}
            type="primary"
            size="large"
            style={{
              backgroundColor: "#578C80",
            }}
          >
            Add New Menu Item
          </Button>
        </div>
        <div className="row-span-2">
          <Form form={form} component={false}>
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <Spin size="large" />
              </div>
            ) : (
              <CustomTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                columns={mergedColumns}
                rowClassName="editable-row"
                dataSource={dataSource}
                className="mx-4 mt-5 pb-10"
                pagination={{ defaultPageSize: 8, position: ["bottomCenter"] }}
              />
            )}
          </Form>
        </div>
      </div>
      <FooterNavBar />
      <AddNewMenuItemModal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
}
