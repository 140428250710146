import axiosInstance from "../helpers/axiosInstance";
import { deserialise } from "kitsu-core";

const TableService = {
  storeTables: async (tables) => {
    return await Promise.all(
      tables.map(async (table) => {
        const params = {
          x: table.x,
          y: table.y,
          shape: table.shape,
          name: table.name,
          status: table.status,
        };

        if (table.pending) {
          const result = await axiosInstance.post("/tables", params);
          return formatTable(deserialise(result.data).data);
        }

        const result = await axiosInstance.put(`/tables/${table.id}`, params);
        return formatTable(deserialise(result.data).data);
      }),
    );
  },

  deleteTable: async (tableId) => {
    await axiosInstance.delete(`/tables/${tableId}`);
  },

  getTables: async () => {
    const result = await axiosInstance.get("/tables");
    return deserialise(result.data).data.map(formatTable);
  },

  getTable: async (tableId) => {
    const result = await axiosInstance.get(`/tables/${tableId}`);
    return formatTable(deserialise(result.data).data);
  },
};

const formatTable = (table) => {
  return {
    ...table,
    isDragging: false,
    x: parseFloat(table.x),
    y: parseFloat(table.y),
    pending: false,
  };
};

export default TableService;
