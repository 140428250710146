import React from "react";
import { Table, Input, Button, Space, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function CustomTable({
  dataSource,
  columns,
  className,
  onRow,
  onSearch,
  pagination,
  components,
  rowClassName,
}) {
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    onSearch(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    onSearch("");
  };

  const enhancedColumns = columns.map((col) => {
    if (col.searchable) {
      return {
        ...col,
        ...getColumnSearchProps(col.dataIndex),
      };
    }
    return col;
  });

  return (
    <div className={className}>
      <Table
        rowClassName={rowClassName}
        components={components}
        dataSource={dataSource}
        columns={enhancedColumns}
        onRow={onRow}
        className={className}
        pagination={pagination}
      />
    </div>
  );
}
