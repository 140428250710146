import { Circle, Group, Text } from "react-konva";
import { handleDragBound } from "../../helpers/table-helper";
import { useState } from "react";

export default function CircleTable({
  id,
  x,
  y,
  handleDragStart,
  handleDragEnd,
  editable,
  onClick,
  status,
}) {
  const SHAPE_SIZE = 50;
  const [textX, setTextX] = useState(x);
  const [textY, setTextY] = useState(y);

  return (
    <Group
      dragBoundFunc={(pos) => handleDragBound(pos, SHAPE_SIZE, SHAPE_SIZE)}
    >
      <Circle
        dragBoundFunc={(pos) => handleDragBound(pos, SHAPE_SIZE, SHAPE_SIZE)}
        id={id}
        x={x}
        y={y}
        radius={SHAPE_SIZE}
        fill={status === "available" ? "#89b717" : "#EBD188"}
        opacity={0.8}
        draggable={editable}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragMove={(e) => {
          setTextX(e.target.x());
          setTextY(e.target.y());
        }}
        onClick={onClick}
        onTap={onClick}
      />
      <Text
        text={`T${id}`}
        x={textX - 15}
        y={textY - 10}
        draggable={false}
        fontSize={20}
      />
    </Group>
  );
}
