import React, { useEffect, useState } from "react";
import { Tooltip, Button } from "antd";
import Modal from "../components/Modal/Modal";
import OrderItemService from "../services/orderItemService";
import CustomTable from "../components/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";

const StatusButton = ({ status, onClick }) => {
  let buttonProps = {
    type: "default",
    onClick,
  };

  switch (status) {
    case "ordered":
      buttonProps = {
        ...buttonProps,
        type: "default",
        children: "Start Preparation",
      };
      break;
    case "in_progress":
      buttonProps = {
        ...buttonProps,
        type: "default",
        children: "Mark as Done",
      };
      break;
    case "completed":
      buttonProps = {
        ...buttonProps,
        type: "success",
        children: "Completed",
        disabled: true,
      };
      break;
    default:
      buttonProps = {
        ...buttonProps,
        children: "Unknown Status",
        disabled: true,
      };
  }

  return (
    <Tooltip title={buttonProps.children}>
      <Button {...buttonProps} />
    </Tooltip>
  );
};

const renderMenuItemTable = (menuItems, orderedItems, handleStatusClick) => {
  return (
    <CustomTable
      pagination={false}
      dataSource={orderedItems.map((orderedItem) => {
        const menuItem = menuItems.find(
          (item) => item.id === orderedItem.menu_item.data.id,
        );
        return {
          key: orderedItem.id,
          name: menuItem.name,
          price: `${orderedItem.price} lv.`,
          total: `${orderedItem.price * orderedItem.quantity} lv.`,
          status: orderedItem.status,
          quantity: orderedItem.quantity,
        };
      })}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          onCell: (_) => ({ width: "350px" }),
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
          onCell: (_) => ({ width: "50px" }),
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          onCell: (_) => ({ width: "100px" }),
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          onCell: (_) => ({ width: "100px" }),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text) => {
            let color = "black";
            if (text === "in_progress") {
              color = "orange";
            } else if (text === "completed") {
              color = "green";
            }
            return <span style={{ color: color }}>{text}</span>;
          },
          onCell: (_) => ({ width: "200px" }),
        },
        {
          title: "",
          dataIndex: "status",
          key: "x",
          render: (status, record) => (
            <StatusButton
              status={status}
              onClick={() => handleStatusClick(record.key, status)}
            />
          ),
          onCell: (_) => ({ width: "200px" }),
        },
      ]}
    />
  );
};

const OrderDetailsModal = ({ open, onCancel, onOk, chosenOrder }) => {
  const [selectedOrder, setSelectedOrder] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (chosenOrder) {
      setSelectedOrder(chosenOrder);
    }
  }, [chosenOrder]);

  const handleStatusClick = async (orderItemId, currentStatus) => {
    try {
      const nextStatus =
        currentStatus === "ordered" ? "in_progress" : "completed";
      await OrderItemService.changeOrderItemStatus(orderItemId, nextStatus);

      setSelectedOrder(
        (prevOrder) => {
          const updatedOrderItems = prevOrder.order_items.data.map((item) => {
            if (item.id === orderItemId) {
              return { ...item, status: nextStatus };
            }
            return item;
          });

          return {
            ...prevOrder,
            order_items: { data: updatedOrderItems },
          };
        },
        [selectedOrder],
      );
    } catch (e) {
      console.log(e.message);
    }
  };

  const allItemsCompleted = selectedOrder?.order_items?.data.every(
    (item) => item.status === "completed",
  );

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        size: "large",
        className: "bg-strandja-dark-cyan",
        disabled: !allItemsCompleted,
      }}
      cancelButtonProps={{ size: "large" }}
      title={selectedOrder?.name}
      okText={"Complete Order"}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      )}
    >
      {selectedOrder && (
        <>
          {selectedOrder.menu_items &&
            renderMenuItemTable(
              selectedOrder.menu_items.data,
              selectedOrder.order_items.data.sort((a, b) => a.id - b.id),
              handleStatusClick,
            )}
          <div className="flex float-right mt-12 ">
            <h2 className="text-2xl font-bold">
              Total: {selectedOrder.total} lv.
            </h2>
          </div>
          <button
            className="bg-strandja-dark-cyan text-white px-4 py-2 rounded-lg float-right flex float-left mt-12"
            onClick={() =>
              navigate(
                `/orderDetails?orderId=${selectedOrder.id}&tableId=${selectedOrder.table.data.id}`,
              )
            }
          >
            Edit Order
          </button>
        </>
      )}
    </Modal>
  );
};

export default OrderDetailsModal;
