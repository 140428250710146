// MenuService.js
import { deserialise } from "kitsu-core";
import axiosInstance from "../helpers/axiosInstance";

const MenuService = {
  getMenuItemsByCategory: async (category) => {
    try {
      const response = await axiosInstance.get(
        `/menu_items?filter[item_type]=${category}`,
        {},
      );
      return deserialise(response.data).data;
    } catch (error) {
      console.error("There was an error fetching the menu items:", error);
      throw error;
    }
  },
  getMenuItems: async () => {
    try {
      const response = await axiosInstance.get("/menu_items", {});
      return deserialise(response.data).data;
    } catch (error) {
      console.error("There was an error fetching the menu items:", error);
      throw error;
    }
  },

  deleteMenuItem: async (menuItemId) => {
    try {
      await axiosInstance.delete(`/menu_items/${menuItemId}`);
    } catch (error) {
      console.log(error.message);
    }
  },
  updateMenuItem: async (menu_item, menuItemId) => {
    try {
      const response = await axiosInstance.patch(`/menu_items/${menuItemId}`, {
        menu_item,
      });
      return response;
    } catch (error) {
      console.log(error.message);
    }
  },
  createMenuItem: async (menu_item) => {
    try {
      const response = await axiosInstance.post("/menu_items", { menu_item });
      return response;
    } catch (e) {
      console.log(e.message);
    }
  },
};

export default MenuService;
