import { Rect, Group, Text } from "react-konva";
import { handleDragBound } from "../../helpers/table-helper";
import { useState } from "react";

export default function VerticalRect({
  id,
  x,
  y,
  handleDragStart,
  handleDragEnd,
  editable,
  onClick,
  status,
}) {
  const SHAPE_WIDTH = 100;
  const SHAPE_HEIGHT = 200;
  const [textX, setTextX] = useState(x);
  const [textY, setTextY] = useState(y);

  return (
    <Group
      dragBoundFunc={(pos) => handleDragBound(pos, SHAPE_WIDTH, SHAPE_HEIGHT)}
    >
      <Rect
        dragBoundFunc={(pos) => handleDragBound(pos, SHAPE_WIDTH, SHAPE_HEIGHT)}
        id={id}
        x={x}
        y={y}
        width={SHAPE_WIDTH}
        height={SHAPE_HEIGHT}
        fill={status === "available" ? "#89b717" : "#EBD188"}
        opacity={0.8}
        draggable={editable}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragMove={(e) => {
          setTextX(e.target.x());
          setTextY(e.target.y());
        }}
        onClick={onClick}
        onTap={onClick}
      />
      <Text
        text={`T${id}`}
        x={textX + SHAPE_WIDTH / 2 - 20}
        y={textY + SHAPE_HEIGHT / 2 - 10}
        draggable={false}
        fontSize={20}
      />
    </Group>
  );
}
