import axiosInstance from "../helpers/axiosInstance";
import { deserialise } from "kitsu-core";

const OrderItemService = {
    changeOrderItemStatus: async (orderItemId, status) => {
        try {
            const response = await axiosInstance.patch(`/order_items/${orderItemId}`, {
                order_item: {
                    status: `${status}`,
                }
            });

            const result = response.data;
            return deserialise(result);
        } catch (error) {
            console.error('Error completing the order:', error);
            throw error;
        }
    }
};

export default OrderItemService;