import React from "react";
import { Button } from "antd";
import {
  ReadOutlined,
  LogoutOutlined,
  SolutionOutlined,
  ShopOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import AuthenticationService from "../../services/authenticationService";

export default function FooterNavBar() {
  const handleLogout = () => {
    AuthenticationService.logout();
  };

  return (
    <div className="relative">
      <nav className="fixed inset-x-0 bottom-0 flex justify-between bg-strandja-yellow p-4 px-20">
        <div>
          <Link to="/tables">
            <Button
              type="text"
              size="large"
              icon={<ShopOutlined />}
              className={`text-xl mt-2 hover:bg-strandja-dark-red  active:bg-strandja-medium-red`}
            >
              Tables
            </Button>
          </Link>
        </div>
        {/* <div>
          <Link to="/takeout">
            <Button
              type="text"
              size="large"
              icon={<CarOutlined />}
              className={`text-xl mt-2 hover:bg-strandja-dark-red  active:bg-strandja-medium-red`}
            >
              Takeout
            </Button>
          </Link>
        </div> */}
        <div>
          <Link to="/orders">
            <Button
              type="text"
              size="large"
              icon={<SolutionOutlined />}
              className={`text-xl mt-2 hover:bg-strandja-dark-red active:bg-strandja-medium-red`}
            >
              Orders
            </Button>
          </Link>
        </div>
        <div>
          <Link to="/menuItems">
            <Button
              type="text"
              size="large"
              icon={<ReadOutlined />}
              className={`text-xl mt-2 hover:bg-strandja-dark-red active:bg-strandja-medium-red`}
            >
              Menu
            </Button>
          </Link>
        </div>
        <div>
          <Button
            type="text"
            size="large"
            icon={<LogoutOutlined />}
            className="text-xl mt-2"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </nav>
    </div>
  );
}
