import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import LoginPage from "./pages/LoginPage";
import MenuPage from "./pages/MenuPage";
import TakeoutPage from "./pages/TakeoutPage";
import HomePage from "./pages/HomePage";
import OrdersPage from "./pages/OrdersPage";
import TablesPage from "./pages/TablesPage";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./helpers/privateRoute";
import MenuItemsPage from "./pages/MenuItemsPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "admin",
        element: <LoginPage />,
      },
      {
        path: "menu",
        element: <MenuPage />,
      },
      // {
      //   path: "takeout",
      //   element: (
      //     <PrivateRoute>
      //       <TakeoutPage />
      //     </PrivateRoute>
      //   ),
      // },
      {
        index: true,
        element: (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        ),
      },
      {
        path: "orders",
        element: (
          <PrivateRoute>
            <OrdersPage />
          </PrivateRoute>
        ),
      },
      {
        path: "menuItems",
        element: (
          <PrivateRoute>
            <MenuItemsPage />
          </PrivateRoute>
        ),
      },
      {
        path: "tables",
        element: (
          <PrivateRoute>
            <TablesPage />
          </PrivateRoute>
        ),
      },
      {
        path: "orderDetails",
        element: (
          <PrivateRoute>
            <OrderDetailsPage />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
