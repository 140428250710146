import React from 'react';
import { Modal as AntdModal } from 'antd';
import './Modal.css';

export default function Modal({open, onCancel, title, children, footer, okText, cancelText, onOk, okButtonProps, cancelButtonProps})
{
    return(
    <AntdModal
      centered
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      className="custom-modal"
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      footer={footer}
      width={1000}
      okText={okText}
      cancelText={cancelText}
    >
      <div className="custom-modal-header">
        <span className="custom-modal-title">{title}</span>
      </div>
      <div className="custom-modal-body">
        {children}
      </div>
      {footer && (
        <div className="custom-modal-footer">
          {footer}
        </div>
      )}
    </AntdModal>
    );
}